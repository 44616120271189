<template>
    <div class="main">
        <agent-info-comp></agent-info-comp>

        <div class="search">
            <div class="refresh" @click="getLogList"><i class="fa fa-refresh"></i></div>
            <!--파트너 선택-->
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="startDate"
                                align="right"
                                type="date"
                                placeholder="시작일 선택"
                                :picker-options="pickerOptions"
                                @change="setStartDate">
                </el-date-picker>
            </div>
            <div style="padding-left: 2px;padding-right: 2px">~</div>
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="endDate"
                                align="right"
                                type="date"
                                placeholder="마감일 선택"
                                :picker-options="pickerOptions"
                                @change="setEndDate">
                </el-date-picker>
            </div>
            <el-button type="primary" @click="getLogList" size="mini" style="margin-left: 10px">검색</el-button>
        </div>

        <div class="data">
            <el-table
                    :data="logList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="머니 or 포인트"
                        width="110">
                    <template slot-scope="scope">
                        <span v-if="scope.row.cashOrPoint == agentConst.CashPointLog.LOG_CASHPOINT_CASH">머니</span>
                        <span v-if="scope.row.cashOrPoint == agentConst.CashPointLog.LOG_CASHPOINT_POINT">Point</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="전금액"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.oldValue|comma}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="수량"
                        width="100">
                    <template slot-scope="scope">
                       <span class="text-blue"
                             v-if="scope.row.plusOrMinus == agentConst.CashPointLog.LOG_CASHPOINT_PLUS">+{{scope.row.amount | comma}}</span>
                        <span class="text-danger"
                              v-if="scope.row.plusOrMinus == agentConst.CashPointLog.LOG_CASHPOINT_MINUS">-{{scope.row.amount | comma}}</span>

                    </template>
                </el-table-column>
                <el-table-column
                        label="현재"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.currentValue|comma}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="내용"
                        width="500">
                    <template slot-scope="scope">

                      <span class="bg-skyblue" v-if="
                         scope.row.type == agentConst.CashPointLog.LOG_CASH_TYPE_MANAGER_ADDCASH
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASH_TYPE_MANAGER_MINUSCASH
                       ||scope.row.type == agentConst.CashPointLog.LOG_POINT_TYPE_MANAGER_ADDPOINT
                       ||scope.row.type == agentConst.CashPointLog.LOG_POINT_TYPE_MANAGER_MINUSPOINT
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_USER_PLUS_MOVE_2_USER
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_PARTNER_PLUS_MOVE_2_USER
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_PARTNER_PLUS_MOVE_2_DAILI
                       ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_USER_PLUS_MOVE_2_DAILI"> {{scope.row.content}}</span>

                      <span class="bg-red" v-else-if="
                       scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_USER_MINUS_MOVE_2_USER
                      ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_USER_MINUS_MOVE_2_DAILI
                      ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_USER
                      ||scope.row.type == agentConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_DAILI"> {{scope.row.content}}</span>

                      <span class="" v-else> {{scope.row.content}}</span>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import {Loading} from "element-ui";
    import {agentMixin} from "../../common/agent/agentMixin";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {getLogCashPointList2} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentCashPointLog",
        mixins: [agentMixin],
        components: {AgentInfoComp, PartnerSubSelectorComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 50,
                pageTotal: 0,
                log: {},
                logList: [],
                totalAmount: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        methods: {
            selectCashOrPoint() {
                this.log.type = null
                this.getLogList()
            },

            getLogList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.log.startDate = this.startDate;
                this.log.endDate = this.endDate;
                getLogCashPointList2(this.log, this.pageNum, this.pageSize).then(res => {
                    this.logList = res.data.data.result
                    this.pageTotal = res.data.data.pageTotal
                    this.totalAmount = res.data.data.totalAmount;
                    loadingInstance.close()
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLogList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLogList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
            setPath() {
                this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                this.endDate = this.$moment().format('yyyy-MM-DD')
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.log = {}
                this.log.partnerId = partnerId
            },
        },
        created() {
            this.setPath()
            this.getLogList()
        },
        watch: {
            $route: function (to, from) {
                this.setPath()
                this.getLogList()
            },
        }
    }
</script>

<style scoped>

</style>